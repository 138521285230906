<template>
  <!-- 工地形象->按主要负责人统计 -->
  <div>
    <s-search
      :queryItems="queryItems"
      @fetchData="fetchPricipal"
      @mode="GetMode"
      @getDates="getDates"
      :bc_id="bc_id"
    ></s-search>
    <div class="table_top">
      <div class="table-button">
        <a-button
          type="plain"
          icon="cloud-download"
          @click="handleExport"
          v-if="
            isShowBtns.indexOf('SiteImage-/api/count/wscountbossexport') > -1
          "
          >导出</a-button
        >
        <columns-set
          tableName="Principal"
          :columns="columns"
          @changeColumns="changeColumns"
        ></columns-set>
      </div>
    </div>
    <s-table
      ref="table"
      size="default"
      bordered
      rowKey="w_name"
      :columns="tableColumns"
      :data="loadData"
      :showPagination="true"
      :scroll="{ x: 2250, y: 'calc(82vh - 150px )' }"
    >
      <!-- :scroll="{ x: 1000 }" -->
      <!-- <span slot="serial" slot-scope="text, record, index">
        {{ (pageNo - 1) * pageSize + index + 1 }}
      </span> -->
      <span slot="施工中工地数" class="TitleFlex">
        <div class="Title_icon">
          <p
            class="Title_up"
            @click="sortColumn(1, 2, 'now_ws_onworknumBlue')"
            :class="now_ws_onworknumBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-up" />
          </p>
          <p
            class="Title_down"
            @click="sortColumn(1, 1, 'now_ws_onworknumDownBlue')"
            :class="now_ws_onworknumDownBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-down" />
          </p>
        </div>
        <div>施工中工地数</div>
        <div>
          <a-tooltip>
            <template slot="title">每个负责人目前施工中的工地数量</template>
            <a-icon type="question-circle" class="ws_nameTitle_icon" />
          </a-tooltip>
        </div>
      </span>
      <span slot="已绑定工地数" class="TitleFlex">
        <div class="Title_icon">
          <p
            class="Title_up"
            @click="sortColumn(2, 2, 'now_ws_bindworknumBlue')"
            :class="now_ws_bindworknumBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-up" />
          </p>
          <p
            class="Title_down"
            @click="sortColumn(2, 1, 'now_ws_bindworknumDownBlue')"
            :class="now_ws_bindworknumDownBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-down" />
          </p>
        </div>
        <div>已绑定工地数</div>
        <div>
          <a-tooltip>
            <template slot="title"
              >每个负责人目前绑定设备工地的工地数量</template
            >
            <a-icon type="question-circle" class="ws_nameTitle_icon" />
          </a-tooltip>
        </div>
      </span>
      <!-- 在线设备数 -->
      <span slot="在线设备数" class="TitleFlex">
        <div>在线设备数</div>
        <div class="Title_icon">
          <p
            class="Title_up"
            @click="sortColumn(3, 2, 'now_online_deviceBlue')"
            :class="now_online_deviceBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-up" />
          </p>
          <p
            class="Title_down"
            @click="sortColumn(3, 1, 'now_online_deviceDownBlue')"
            :class="now_online_deviceDownBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-down" />
          </p>
        </div>
      </span>
      <span slot="员工抽烟总次数" class="TitleFlex">
        <div class="Title_icon">
          <p
            class="Title_up"
            @click="sortColumn(4, 2, 'now_smokeworkernumBlue')"
            :class="now_smokeworkernumBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-up" />
          </p>
          <p
            class="Title_down"
            @click="sortColumn(4, 1, 'now_smokeworkernumDownBlue')"
            :class="now_smokeworkernumDownBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-down" />
          </p>
        </div>
        <div>员工抽烟总次数</div>
        <div>
          <a-tooltip>
            <template slot="title">负责人所负责的工地员工报警总次数</template>
            <a-icon type="question-circle" class="ws_nameTitle_icon" />
          </a-tooltip>
        </div>
      </span>
      <span slot="非员工抽烟总次数" class="TitleFlex">
        <div class="Title_icon">
          <p
            class="Title_up"
            @click="sortColumn(5, 2, 'now_smokeunworkernumBlue')"
            :class="now_smokeunworkernumBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-up" />
          </p>
          <p
            class="Title_down"
            @click="sortColumn(5, 1, 'now_smokeunworkernumDownBlue')"
            :class="now_smokeunworkernumDownBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-down" />
          </p>
        </div>
        <div>非员工抽烟总次数</div>
        <div>
          <a-tooltip>
            <template slot="title"
              >负责人所负责的工地“非员工”报警总次数</template
            >
            <a-icon type="question-circle" class="ws_nameTitle_icon" />
          </a-tooltip>
        </div>
      </span>
      <span slot="未穿工服总次数" class="TitleFlex">
        <div class="Title_icon">
          <p
            class="Title_up"
            @click="sortColumn(6, 2, 'now_clothesworkernumBlue')"
            :class="now_clothesworkernumBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-up" />
          </p>
          <p
            class="Title_down"
            @click="sortColumn(6, 1, 'now_clothesworkernumDownBlue')"
            :class="now_clothesworkernumDownBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-down" />
          </p>
        </div>
        <div>未穿工服总次数</div>
        <div>
          <a-tooltip>
            <template slot="title"
              >负责人所负责的工地员工未穿工服报警总次数</template
            >
            <a-icon type="question-circle" class="ws_nameTitle_icon" />
          </a-tooltip>
        </div>
      </span>
      <span slot="整洁度优总次数" class="TitleFlex">
        <div class="Title_icon">
          <p
            class="Title_up"
            @click="sortColumn(7, 2, 'now_cleanworknumBlue')"
            :class="now_cleanworknumBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-up" />
          </p>
          <p
            class="Title_down"
            @click="sortColumn(7, 1, 'now_cleanworknumDownBlue')"
            :class="now_cleanworknumDownBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-down" />
          </p>
        </div>
        <div>整洁度优总次数</div>
        <div>
          <a-tooltip>
            <template slot="title">负责人所负责的工地整洁度优总次数</template>
            <a-icon type="question-circle" class="ws_nameTitle_icon" />
          </a-tooltip>
        </div>
      </span>
      <span slot="整洁度良总次数" class="TitleFlex">
        <div class="Title_icon">
          <p
            class="Title_up"
            @click="sortColumn(8, 2, 'now_goodworknumBlue')"
            :class="now_goodworknumBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-up" />
          </p>
          <p
            class="Title_down"
            @click="sortColumn(8, 1, 'now_goodworknumDownBlue')"
            :class="now_goodworknumDownBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-down" />
          </p>
        </div>
        <div>整洁度良总次数</div>
        <div>
          <a-tooltip>
            <template slot="title">负责人所负责的工地整洁度良总次数</template>
            <a-icon type="question-circle" class="ws_nameTitle_icon" />
          </a-tooltip>
        </div>
      </span>
      <span slot="整洁度差总次数" class="TitleFlex">
        <div class="Title_icon">
          <p
            class="Title_up"
            @click="sortColumn(9, 2, 'now_badworknumBlue')"
            :class="now_badworknumBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-up" />
          </p>
          <p
            class="Title_down"
            @click="sortColumn(9, 1, 'now_badworknumDownBlue')"
            :class="now_badworknumDownBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-down" />
          </p>
        </div>
        <div>整洁度差总次数</div>
        <div>
          <a-tooltip>
            <template slot="title">负责人所负责的工地整洁度差总次数</template>
            <a-icon type="question-circle" class="ws_nameTitle_icon" />
          </a-tooltip>
        </div>
      </span>
      <span slot="摄像头歪曲总次数" class="TitleFlex">
        <div class="Title_icon">
          <p
            class="Title_up"
            @click="sortColumn(10, 2, 'now_camerabadnumBlue')"
            :class="now_camerabadnumBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-up" />
          </p>
          <p
            class="Title_down"
            @click="sortColumn(10, 1, 'now_camerabadnumDownBlue')"
            :class="now_camerabadnumDownBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-down" />
          </p>
        </div>
        <div>摄像头歪曲总次数</div>
        <div>
          <a-tooltip>
            <template slot="title">负责人所负责的工地摄像头歪曲总次数</template>
            <a-icon type="question-circle" class="ws_nameTitle_icon" />
          </a-tooltip>
        </div>
      </span>
      <span slot="w_post" slot-scope="text">
        {{ text }}
      </span>
      <span slot="now_ws_onworknum" slot-scope="text, record">
        <span
          class="nums"
          @click="goToSiteList(record.w_name, '0', record.w_post)"
        >
          {{ text }}
        </span>
      </span>
      <span slot="now_ws_bindworknum" slot-scope="text, record">
        <span
          class="nums"
          @click="goToSiteList(record.w_name, '2', record.w_post)"
        >
          {{ text }}
        </span>
      </span>
      <span slot="now_smokeworkernum" slot-scope="text, record">
        <span
          class="nums"
          @click="goToAlarmEvent('1', 'smoking', record.w_name, record.w_post)"
        >
          {{ text }}
        </span>
        <span
          :class="
            text - record.old_smokeworkernum > 0
              ? 'red'
              : text - record.old_smokeworkernum < 0
              ? 'green'
              : 'black'
          "
        >
          {{ text - record.old_smokeworkernum < 0 ? "" : "+"
          }}{{ text - record.old_smokeworkernum }}
        </span>
      </span>
      <span slot="now_smokeunworkernum" slot-scope="text, record">
        <span
          class="nums"
          @click="goToAlarmEvent('2', 'smoking', record.w_name, record.w_post)"
        >
          {{ text }}
        </span>
        <span
          :class="
            text - record.old_smokeunworkernum > 0
              ? 'red'
              : text - record.old_smokeunworkernum < 0
              ? 'green'
              : 'black'
          "
        >
          {{ text - record.old_smokeunworkernum < 0 ? "" : "+"
          }}{{ text - record.old_smokeunworkernum }}
        </span>
      </span>
      <span slot="now_clothesworkernum" slot-scope="text, record">
        <span
          class="nums"
          @click="
            goToAlarmEvent('1', 'noclothes', record.w_name, record.w_post)
          "
        >
          {{ text }}
        </span>
        <span
          :class="
            text - record.old_clothesworkernum > 0
              ? 'red'
              : text - record.old_clothesworkernum < 0
              ? 'green'
              : 'black'
          "
        >
          {{ text - record.old_clothesworkernum < 0 ? "" : "+"
          }}{{ text - record.old_clothesworkernum }}
        </span>
      </span>
      <span slot="now_cleanworknum" slot-scope="text, record">
        <span
          class="nums"
          @click="goToSiteNeat(record.w_name, record.w_post, '1')"
        >
          {{ text }}
        </span>
        <span
          :class="
            text - record.old_cleanworknum > 0
              ? 'red'
              : text - record.old_cleanworknum < 0
              ? 'green'
              : 'black'
          "
        >
          {{ text - record.old_cleanworknum < 0 ? "" : "+"
          }}{{ text - record.old_cleanworknum }}
        </span>
      </span>
      <span slot="now_goodworknum" slot-scope="text, record">
        <span
          class="nums"
          @click="goToSiteNeat(record.w_name, record.w_post, '2')"
        >
          {{ text }}
        </span>
        <span
          :class="
            text - record.old_goodworknum > 0
              ? 'red'
              : text - record.old_goodworknum < 0
              ? 'green'
              : 'black'
          "
        >
          {{ text - record.old_goodworknum < 0 ? "" : "+"
          }}{{ text - record.old_goodworknum }}
        </span>
      </span>
      <span slot="now_badworknum" slot-scope="text, record">
        <span
          class="nums"
          @click="goToSiteNeat(record.w_name, record.w_post, '3')"
        >
          {{ text }}
        </span>
        <span
          :class="
            text - record.old_badworknum > 0
              ? 'red'
              : text - record.old_badworknum < 0
              ? 'green'
              : 'black'
          "
        >
          {{ text - record.old_badworknum < 0 ? "" : "+"
          }}{{ text - record.old_badworknum }}
        </span>
      </span>
      <span slot="now_camerabadnum" slot-scope="text, record">
        <span
          class="nums"
          @click="goToSiteNeat(record.w_name, record.w_post, '4')"
        >
          {{ text }}
        </span>
        <span
          :class="
            text - record.old_camerabadnum > 0
              ? 'red'
              : text - record.old_camerabadnum < 0
              ? 'green'
              : 'black'
          "
        >
          {{ text - record.old_camerabadnum < 0 ? "" : "+"
          }}{{ text - record.old_camerabadnum }}
        </span>
      </span>
    </s-table>
    <a-modal
      class="tips-modal"
      title="提示"
      okText="确定"
      cancelText="取消"
      :visible="visibleExport"
      :confirm-loading="confirmExportLoading"
      @ok="exportOk"
      @cancel="exportCancel"
    >
      <p>确定导出？</p>
    </a-modal>
  </div>
</template>

<script>
import { getWscountboss } from "@/api/dataStatistics.js";
import { STable, ColumnsSet, SSearch } from "@/components";
import { GetWorkerType, GetWorkerPost } from "@/api/worker";
import { SelectOptions } from "@/config/SelectOptions.js";
import { ExportWscountboss } from "@/api/export";
import moment from "moment";

// 搜索条件
const queryItems = [
  { label: "负责人姓名", value: "w_name", type: "input", defaultValue: "" },
  {
    label: "人员所属分公司",
    value: "bc_id",
    type: "select",
    selectOption: "CompanyList",
    defaultValue: "",
  },
  {
    label: "岗位",
    value: "w_post",
    type: "select",
    selectOption: "DeviceWorkPost",
    defaultValue: "",
  },
  {
    label: "日期查询",
    value: "find_time",
    type: "dateOnly",
    defaultValue: "",
  },
];
const columns = [
  // {
  //   title: "序号",
  //   width: "70px",
  //   scopedSlots: { customRender: "serial" },
  //   dataIndex: "serial",
  //   align: "center",
  // },

  {
    title: "负责人姓名",
    width: "140px",
    dataIndex: "w_name",
    align: "center",
    fixed: "left",
  },
  {
    title: "人员所属分公司",
    // width: "100px",
    dataIndex: "bc_name",
    align: "center",
  },
  {
    title: "岗位",
    width: "120px",
    dataIndex: "w_post",
    scopedSlots: { customRender: "w_post" },
    align: "center",
  },
  {
    // title: "施工中工地数",
    width: "170px",
    dataIndex: "now_ws_onworknum",
    scopedSlots: { customRender: "now_ws_onworknum" },
    align: "center",
    slots: { title: "施工中工地数" },
  },
  {
    // title: "已绑定工地数",
    width: "170px",
    dataIndex: "now_ws_bindworknum",
    scopedSlots: { customRender: "now_ws_bindworknum" },
    slots: { title: "已绑定工地数" },
    align: "center",
  },
  {
    // title: "在线设备数",
    width: "130px",
    dataIndex: "now_online_device",
    slots: { title: "在线设备数" },
    align: "center",
  },
  {
    // title: "员工抽烟总次数",
    width: "185px",
    dataIndex: "now_smokeworkernum",
    scopedSlots: { customRender: "now_smokeworkernum" },
    slots: { title: "员工抽烟总次数" },
    align: "center",
  },
  {
    // title: "非员工抽烟总次数",
    width: "200px",
    dataIndex: "now_smokeunworkernum",
    scopedSlots: { customRender: "now_smokeunworkernum" },
    slots: { title: "非员工抽烟总次数" },
    align: "center",
  },
  {
    // title: "未穿工服总次数",
    width: "190px",
    dataIndex: "now_clothesworkernum",
    scopedSlots: { customRender: "now_clothesworkernum" },
    slots: { title: "未穿工服总次数" },
    align: "center",
  },
  {
    // title: "整洁度优总次数",
    width: "190px",
    dataIndex: "now_cleanworknum",
    scopedSlots: { customRender: "now_cleanworknum" },
    slots: { title: "整洁度优总次数" },
    align: "center",
  },
  {
    // title: "整洁度良总次数",
    width: "190px",
    dataIndex: "now_goodworknum",
    scopedSlots: { customRender: "now_goodworknum" },
    slots: { title: "整洁度良总次数" },
    align: "center",
  },
  {
    // title: "整洁度差总次数",
    width: "190px",
    dataIndex: "now_badworknum",
    scopedSlots: { customRender: "now_badworknum" },
    slots: { title: "整洁度差总次数" },
    align: "center",
  },
  {
    // title: "摄像头歪曲总次数",
    width: "200px",
    dataIndex: "now_camerabadnum",
    scopedSlots: { customRender: "now_camerabadnum" },
    slots: { title: "摄像头歪曲总次数" },
    align: "center",
  },
];
const orderParam = [
  "w_name",
  "bc_id",
  "w_post",
  "find_type",
  "find_time",
  "sort_column",
  "sort_rule",
  "pageNo",
  "pageSize",
];
export default {
  name: "Principal",
  components: {
    STable,
    ColumnsSet,
    SSearch,
  },
  props: {
    routerPath: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    this.queryItems = queryItems;
    this.columns = columns;
    const tableSize = { x: 1500, y: document.body.clientHeight - 380 };
    return {
      tableColumns: [],
      total: 0, //总条数,在获取后台数据时将数组的length赋值给total
      // 查询参数
      queryParam: {
        find_type: "1", //查询类型
        find_time: "", //查询开始时间（默认昨天）
        sort_column: "", //
        sort_rule: "", //升序降序
        w_name: "",
        w_post: "",
        bc_id: "",
      },
      bc_id: "",
      dataSource: [],
      selectedRowKeys: [],
      selectedRows: [],
      //当前页数，当前条数
      pageNo: 1,
      pageSize: 10,
      datepickerType: "date",
      dateValueParents: {},
      advanceFalse: false, //关闭下拉搜索
      now_ws_onworknumBlue: false,
      now_ws_onworknumDownBlue: false,
      now_ws_bindworknumBlue: false,
      now_ws_bindworknumDownBlue: false,
      now_smokeworkernumBlue: false,
      now_smokeworkernumDownBlue: false,
      now_smokeunworkernumBlue: false,
      now_smokeunworkernumDownBlue: false,
      now_clothesworkernumBlue: false,
      now_clothesworkernumDownBlue: false,
      now_cleanworknumBlue: false,
      now_cleanworknumDownBlue: false,
      now_goodworknumBlue: false,
      now_goodworknumDownBlue: false,
      now_badworknumBlue: false,
      now_badworknumDownBlue: false,
      now_camerabadnumBlue: false,
      now_camerabadnumDownBlue: false,
      now_online_deviceBlue: false,
      now_online_deviceDownBlue: false,
      ws_bind_time_start: "",
      ws_bind_time_end: "",
      e_time_start: "",
      e_time_end: "",
      // 导出弹出框
      visibleExport: false,
      confirmExportLoading: false,
      getPathData: null,
      isShowBtns: [],
    };
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange,
      };
    },
  },
  created() {
    this.tableColumns = columns;
    this.filters = SelectOptions;
    let yesterday =
      new Date(new Date().setHours(0, 0, 0, 0)).getTime() - 1000 * 60 * 60 * 24; //昨天天0点的时间戳;
    this.ws_bind_time_start = yesterday / 1000;
    this.ws_bind_time_end = yesterday / 1000;
    this.e_time_start = yesterday / 1000;
    this.e_time_end = yesterday / 1000;
    this.queryItems[3].defaultValue = moment(yesterday);
    this.queryParam.find_time = yesterday / 1000;
    this.changeColumns();
    let routerPath = JSON.parse(JSON.stringify(this.routerPath));
    if (routerPath) {
      for (let item in routerPath) {
        // 默认值的改变
        this.queryItems.forEach((el) => {
          if (el.value == "find_time") {
            el.defaultValue = moment(routerPath.find_time * 1000);
          } else if (el.value == item) {
            el.defaultValue = routerPath[item];
          }
        });
        // 查询值的改变
        for (let index in this.queryParam) {
          if (index == item) {
            this.queryParam[index] = routerPath[item];
          }
        }
      }
      // 处理 跳转  分公司名称
      this.bc_id = this.$route.query.bc_id;
    }
  },
  mounted() {
    this.isShowBtns = sessionStorage.getItem("isShowBtns").split(",");
  },
  watch: {
    // routerPath: {
    //   w_name(newV) {
    //     console.log("1111");
    //     this.getPathData = JSON.parse(JSON.stringify(newV));
    //   },
    //   deep: true,
    // },
  },
  methods: {
    // 自定义表头列
    changeColumns() {
      let ColumnsSet = JSON.parse(localStorage.getItem("PrincipalColumnsSet"));
      let value = ColumnsSet ? ColumnsSet : [];
      let selectColumns = columns.filter((el) => value.includes(el.dataIndex));
      this.tableColumns = selectColumns.length ? selectColumns : columns;
    },
    // 加载数据方法 必须为 Promise 对象
    loadData(parameter) {
      this.pageNo = parameter.pageNo;
      this.pageSize = parameter.pageSize;
      const param = Object.assign({}, parameter, this.queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return getWscountboss(params).then((res) => {
        // this.data = res.data.data;
        res.data.pageNo = res.data.page;
        res.data.totalCount = res.data.sum;
        return res.data;
      });
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    fetchPricipal(value) {
      this.queryParam = value;
      if (this.datepickerType == "month") {
        this.queryParam.find_type = "3";
      } else if (this.datepickerType == "week") {
        this.queryParam.find_type = "2";
      } else {
        this.queryParam.find_type = "1";
      }
      // console.log(value);
      this.$refs.table.refresh();
    },
    GetMode(value) {
      console.log(value);
      this.datepickerType = value;
      let today = new Date(new Date().setHours(0, 0, 0, 0)).getTime(); //当天0点的时间戳;
      if (value == "week") {
        this.e_time_start = this.$beginOfWeek(today - 1000 * 3600 * 24 * 7);
        this.e_time_end = this.$endOfWeek(today - 1000 * 3600 * 24 * 7);
      } else if (value == "month") {
        this.e_time_start = this.$beginOfMonth(today - 1000 * 3600 * 24 * 30);
        this.e_time_end = this.$endOfMonth(today - 1000 * 3600 * 24 * 30);
      } else {
        let yesterday = today - 1000 * 60 * 60 * 24; //昨天天0点的时间戳;
        this.e_time_start = yesterday / 1000;
        this.e_time_end = yesterday / 1000;
      }
    },
    // 排序
    sortColumn(sort_column, sort_rule, type) {
      console.log(sort_column, sort_rule, type);
      this.queryParam.sort_column = sort_column;
      this.queryParam.sort_rule = sort_rule;
      // this.loadData();
      this.$refs.table.refresh(true);

      if (type) {
        // this[type+'Blue']=true
        this.now_ws_onworknumBlue = false;
        this.now_ws_onworknumDownBlue = false;
        this.now_ws_bindworknumBlue = false;
        this.now_ws_bindworknumDownBlue = false;
        this.now_smokeworkernumBlue = false;
        this.now_smokeworkernumDownBlue = false;
        this.now_smokeunworkernumBlue = false;
        this.now_smokeunworkernumDownBlue = false;
        this.now_clothesworkernumBlue = false;
        this.now_clothesworkernumDownBlue = false;
        this.now_cleanworknumBlue = false;
        this.now_cleanworknumDownBlue = false;
        this.now_goodworknumBlue = false;
        this.now_goodworknumDownBlue = false;
        this.now_badworknumBlue = false;
        this.now_badworknumDownBlue = false;
        this.now_camerabadnumBlue = false;
        this.now_camerabadnumDownBlue = false;
        this.now_online_deviceBlue = false;
        this.now_online_deviceDownBlue = false;
        this[type] = true;
        console.log(type);
      }
    },
    getDates(dates) {
      if (this.datepickerType == "week") {
        //工地列表用
        this.ws_bind_time_start = this.$beginOfWeek(dates * 1000);
        this.ws_bind_time_end = this.$endOfWeek(dates * 1000);
        // 报警事件管理/整洁管理用
        this.e_time_start = this.$beginOfWeek(dates * 1000);
        this.e_time_end = this.$endOfWeek(dates * 1000);
      } else if (this.datepickerType == "month") {
        //工地列表用
        this.ws_bind_time_start = this.$beginOfMonth(dates * 1000);
        this.ws_bind_time_end = this.$endOfMonth(dates * 1000);
        // 报警事件管理用
        this.e_time_start = this.$beginOfMonth(dates * 1000);
        this.e_time_end = this.$endOfMonth(dates * 1000);
      } else {
        this.ws_bind_time_start = dates;
        this.ws_bind_time_end = dates;
        this.e_time_start = dates;
        this.e_time_end = dates;
      }
    },
    // 打开新窗口，跳转到工地列表
    // 参数依次为姓名，绑定状态,岗位
    //w_post   1：工程管家，2：项目经理
    goToSiteList(w_name, ws_bind_status, w_post) {
      console.log(w_name, ws_bind_status, w_post);
      // localStorage.setItem("path", "SiteList");
      // const { href } = this.$router.resolve({
      //   path: "/SiteList",
      //   query: {
      //     // ws_bind_time_start: this.ws_bind_time_start,
      //     // ws_bind_time_end: this.ws_bind_time_end,
      //     w_gj_name: w_post == "工程管家" ? w_name : "",
      //     w_name: w_post == "项目经理" ? w_name : "",
      //     ws_bind_status: ws_bind_status, //绑定状态
      //     // ws_status: "", //工地状态施工中不用变
      //     d_status: "0", //设备状态为全部
      //   },
      // });
      // window.open(href, "_blank");
      this.$store.commit("SET_CURRENT", "SiteList");
      this.$router.push({
        path: "/SiteList",
        query: {
          // ws_bind_time_start: this.ws_bind_time_start,
          // ws_bind_time_end: this.ws_bind_time_end,
          w_gj_name: w_post == "工程管家" ? w_name : "",
          w_name: w_post == "项目经理" ? w_name : "",
          ws_bind_status: ws_bind_status, //绑定状态
          // ws_status: "", //工地状态施工中不用变
          d_status: "0", //设备状态为全部
        },
      });
    },
    // 打开新窗口，跳转到报警事件管理
    // 参数依次为是否为员工，报警事件,姓名，岗位
    goToAlarmEvent(eventworker_type, e_events, name, eventworker_post) {
      // localStorage.setItem("path", "AlarmEvent");
      const post = {
        工程管家: "1",
        项目经理: "2",
      };
      function PrincipalWpost(value) {
        return post[value];
      }
      // console.log(PrincipalWpost(eventworker_post));
      // const { href } = this.$router.resolve({
      //   path: "/AlarmEvent",
      //   query: {
      //     e_time_start: this.e_time_start,
      //     e_time_end: this.e_time_end,
      //     e_events: e_events,
      //     si_eventworker: eventworker_type, //是否为员工
      //     // eventworker_post: PrincipalWpost(eventworker_post), //岗位
      //     ws_gj_name: eventworker_post == "工程管家" ? name : "",
      //     ws_jl_name: eventworker_post == "项目经理" ? name : "",
      //   },
      // });
      // window.open(href, "_blank");
      this.$store.commit("SET_CURRENT", "AlarmEvent");

      this.$router.push({
        path: "/AlarmEvent",
        query: {
          e_time_start: this.e_time_start,
          e_time_end: this.e_time_end,
          e_events: e_events,
          si_eventworker: eventworker_type, //是否为员工
          // eventworker_post: PrincipalWpost(eventworker_post), //岗位
          ws_gj_name: eventworker_post == "工程管家" ? name : "",
          ws_jl_name: eventworker_post == "项目经理" ? name : "",
        },
      });
    },
    // 打开新窗口，跳转到整洁管理
    // 参数依次为员工姓名，岗位,事件
    goToSiteNeat(ws_name, post, e_events) {
      console.log(ws_name, post, e_events);
      const Post = {
        工程管家: "1",
        项目经理: "2",
      };
      function PrincipalWpost(value) {
        return Post[value];
      }
      // localStorage.setItem("path", "SiteNeat");
      // const { href } = this.$router.resolve({
      //   path: "/SiteNeat",
      //   query: {
      //     e_time_start: this.e_time_start,
      //     e_time_end: this.e_time_end,
      //     e_events: e_events,
      //     w_name_jl: post == "项目经理" ? ws_name : "",
      //     w_name_gj: post == "工程管家" ? ws_name : "",
      //   },
      // });
      // window.open(href, "_blank");
      this.$store.commit("SET_CURRENT", "SiteNeat");
      this.$router.push({
        path: "/SiteNeat",
        query: {
          e_time_start: this.e_time_start,
          e_time_end: this.e_time_end,
          e_events: e_events,
          w_name_jl: post == "项目经理" ? ws_name : "",
          w_name_gj: post == "工程管家" ? ws_name : "",
        },
      });
    },
    // 导出
    handleExport() {
      this.visibleExport = true;
    },
    exportOk() {
      console.log("ok");
      this.confirmExportLoading = true;
      const params = {
        w_name: this.queryParam.w_name,
        bc_id: this.queryParam.bc_id,
        w_post: this.queryParam.w_post,
        find_type: this.queryParam.find_type,
        find_time: this.queryParam.find_time,
        sort_column: this.queryParam.sort_column,
        sort_rule: this.queryParam.sort_rule,
        now_online_device: this.queryParam.now_online_device,
      };
      ExportWscountboss(params).then((res) => {
        var blob = res;
        var reader = new FileReader();
        reader.readAsDataURL(blob); // 转换为base64，可以直接放入a标签href
        reader.onload = function (e) {
          // 转换完成，创建一个a标签用于下载
          var a = document.createElement("a");
          a.download = "工地形象-按主要负责人统计列表.xls";
          a.href = e.target.result;
          // $("body").append(a);    // 修复firefox中无法触发click
          a.click();
          a.remove();
        };
        this.visibleExport = false;
        this.confirmExportLoading = false;
      });
    },
    exportCancel() {
      this.visibleExport = false;
    },
  },
};
</script>
<style lang="less" scoped>
.nums {
  color: #1890ff;
  cursor: pointer;
}
.green {
  // background-color: #dbedfc;
  padding: 0 10px;
  border-radius: 10px;
  color: green;
}
.black {
  // background-color: #dbedfc;
  padding: 0 10px;
  border-radius: 10px;
  color: black;
}
.red {
  // background-color: #dbedfc;
  padding: 0 10px;
  border-radius: 10px;
  color: red;
}
.ws_nameTitle_icon {
  padding-left: 10px;
  cursor: pointer;
}
.TitleFlex {
  display: flex;
  // line-height: 100%;
  align-items: center;
  .Title_icon {
    margin-right: 10px;
    // margin-top: -10px;
    padding-top: 10px;
  }
  .Title_up {
    // margin-bottom: -15px;
    margin-bottom: 0%;
    // background-color: pink;
    width: 15px;
    height: 10px;
    line-height: 10px;
  }
  .Title_down {
    // margin-top: -15px;
    width: 15px;
    height: 10px;
    line-height: 10px;
    // background-color: blue;
    // :hover {
    //   // color: aqua;
    // }
  }
  .BlueBlock {
    color: rgb(3, 104, 255);
  }
}
</style>