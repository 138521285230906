<template>
  <!-- 工地形象->按工地统计 -->
  <div>
    <s-search
      :queryItems="queryItems"
      @fetchData="fetchPricipal"
      @mode="GetMode"
      @getDates="getDates"
    ></s-search>
    <div class="table_top">
      <div class="table-button">
        <a-button
          type="plain"
          icon="cloud-download"
          @click="handleExport"
          v-if="isShowBtns.indexOf('SiteImage-/api/count/wscountwsexport') > -1"
          >导出</a-button
        >
        <columns-set
          tableName="ConstructionSite"
          :columns="columns"
          @changeColumns="changeColumns"
        ></columns-set>
      </div>
    </div>
    <s-table
      ref="table"
      size="default"
      bordered
      rowKey="serial"
      :columns="tableColumns"
      :data="loadData"
      :showPagination="true"
      :scroll="{ x: 2100, y: 'calc(82vh - 150px )' }"
    >
      <!-- :scroll="{ x: 1000 }" -->
      <span slot="serial" slot-scope="text, record, index">
        {{ (pageNo - 1) * pageSize + index + 1 }}
      </span>
      <span slot="ws_name" slot-scope="text, record" class="blueNum">
        <a-tooltip placement="topLeft">
          <template slot="title">
            <span>{{ text }}</span>
          </template>
          <span
            style="cursor: pointer"
            @click="OpenSiteParticulars(text, record)"
            >{{ text.replace(/直辖市|-北京|-天津/g, "") }}</span
          >
        </a-tooltip>
      </span>
      <span slot="整洁度优总次数" class="TitleFlex">
        <div class="Title_icon">
          <p
            class="Title_up"
            @click="sortColumn(1, 2, 'cleanworknumBlue')"
            :class="cleanworknumBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-up" />
          </p>
          <p
            class="Title_down"
            @click="sortColumn(1, 1, 'cleanworknumDownBlue')"
            :class="cleanworknumDownBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-down" />
          </p>
        </div>
        <div>整洁度优总次数</div>
        <div>
          <a-tooltip>
            <template slot="title"
              >每个工地在一段时间内累计整洁度优总次数</template
            >
            <a-icon type="question-circle" class="ws_nameTitle_icon" />
          </a-tooltip>
        </div>
      </span>
      <span slot="整洁度良总次数" class="TitleFlex">
        <div class="Title_icon">
          <p
            class="Title_up"
            @click="sortColumn(2, 2, 'goodworknumBlue')"
            :class="goodworknumBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-up" />
          </p>
          <p
            class="Title_down"
            @click="sortColumn(2, 1, 'goodworknumDownBlue')"
            :class="goodworknumDownBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-down" />
          </p>
        </div>
        <div>整洁度良总次数</div>
        <div>
          <a-tooltip>
            <template slot="title"
              >每个工地在一段时间内累计整洁度良总次数</template
            >
            <a-icon type="question-circle" class="ws_nameTitle_icon" />
          </a-tooltip>
        </div>
      </span>
      <span slot="整洁度差总次数" class="TitleFlex">
        <div class="Title_icon">
          <p
            class="Title_up"
            @click="sortColumn(3, 2, 'badworknumBlue')"
            :class="badworknumBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-up" />
          </p>
          <p
            class="Title_down"
            @click="sortColumn(3, 1, 'badworknumDownBlue')"
            :class="badworknumDownBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-down" />
          </p>
        </div>
        <div>整洁度差总次数</div>
        <div>
          <a-tooltip>
            <template slot="title"
              >每个工地在一段时间内累计整洁度差总次数</template
            >
            <a-icon type="question-circle" class="ws_nameTitle_icon" />
          </a-tooltip>
        </div>
      </span>
      <span slot="摄像头歪曲总次数" class="TitleFlex">
        <div class="Title_icon">
          <p
            class="Title_up"
            @click="sortColumn(4, 2, 'camerabadnumBlue')"
            :class="camerabadnumBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-up" />
          </p>
          <p
            class="Title_down"
            @click="sortColumn(4, 1, 'camerabadnumDownBlue')"
            :class="camerabadnumDownBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-down" />
          </p>
        </div>
        <div>摄像头歪曲总次数</div>
        <div>
          <a-tooltip>
            <template slot="title"
              >每个工地在一段时间内累计摄像头歪曲总次数</template
            >
            <a-icon type="question-circle" class="ws_nameTitle_icon" />
          </a-tooltip>
        </div>
      </span>
      <span slot="员工抽烟总次数" class="TitleFlex">
        <div class="Title_icon">
          <p
            class="Title_up"
            @click="sortColumn(5, 2, 'smokeworkernumBlue')"
            :class="smokeworkernumBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-up" />
          </p>
          <p
            class="Title_down"
            @click="sortColumn(5, 1, 'smokeworkernumDownBlue')"
            :class="smokeworkernumDownBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-down" />
          </p>
        </div>
        <div>员工抽烟总次数</div>
        <div>
          <a-tooltip>
            <template slot="title"
              >每个工地在一段时间内累计员工抽烟总次数</template
            >
            <a-icon type="question-circle" class="ws_nameTitle_icon" />
          </a-tooltip>
        </div>
      </span>
      <span slot="非员工抽烟总次数" class="TitleFlex">
        <div class="Title_icon">
          <p
            class="Title_up"
            @click="sortColumn(6, 2, 'smokeunworkernumBlue')"
            :class="smokeunworkernumBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-up" />
          </p>
          <p
            class="Title_down"
            @click="sortColumn(6, 1, 'smokeunworkernumDownBlue')"
            :class="smokeunworkernumDownBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-down" />
          </p>
        </div>
        <div>非员工抽烟总次数</div>
        <div>
          <a-tooltip>
            <template slot="title"
              >每个工地在一段时间内累计非员工抽烟总次数</template
            >
            <a-icon type="question-circle" class="ws_nameTitle_icon" />
          </a-tooltip>
        </div>
      </span>
      <span slot="未穿工服报警次数" class="TitleFlex">
        <div class="Title_icon">
          <p
            class="Title_up"
            @click="sortColumn(7, 2, 'clothesworkernumBlue')"
            :class="clothesworkernumBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-up" />
          </p>
          <p
            class="Title_down"
            @click="sortColumn(7, 1, 'clothesworkernumDownBlue')"
            :class="clothesworkernumDownBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-down" />
          </p>
        </div>
        <div>未穿工服报警总次数</div>
        <div>
          <a-tooltip>
            <template slot="title"
              >每个工地在一段时间内累计员工未穿工服总次数</template
            >
            <a-icon type="question-circle" class="ws_nameTitle_icon" />
          </a-tooltip>
        </div>
      </span>
      <span slot="cleanworknum" slot-scope="text, record" class="blueNum">
        <span @click="goToSiteNeat(record.ws_name, '1')"> {{ text }}</span>
      </span>
      <span slot="goodworknum" slot-scope="text, record" class="blueNum">
        <span @click="goToSiteNeat(record.ws_name, '2')"> {{ text }}</span>
      </span>
      <span slot="badworknum" slot-scope="text, record" class="blueNum">
        <span @click="goToSiteNeat(record.ws_name, '3')"> {{ text }}</span>
      </span>
      <span slot="camerabadnum" slot-scope="text, record" class="blueNum">
        <span @click="goToSiteNeat(record.ws_name, '4')"> {{ text }}</span>
      </span>
      <span slot="smokeworkernum" slot-scope="text, record" class="blueNum">
        <span @click="goToAlarmEvent(record.ws_name, 'smoking', '1')">
          {{ text }}</span
        >
      </span>
      <span slot="smokeunworkernum" slot-scope="text, record" class="blueNum">
        <span @click="goToAlarmEvent(record.ws_name, 'smoking', '2')">
          {{ text }}</span
        >
      </span>
      <span slot="clothesworkernum" slot-scope="text, record" class="blueNum">
        <span @click="goToAlarmEvent(record.ws_name, 'noclothes', '1')">
          {{ text }}</span
        >
      </span>

      <span slot="action" slot-scope="text, record" class="blueNum">
        <span @click="OpenSiteParticulars(text, record)"> 详情</span>
      </span>
    </s-table>
    <a-modal
      class="tips-modal"
      title="提示"
      okText="确定"
      cancelText="取消"
      :visible="visibleExport"
      :confirm-loading="confirmExportLoading"
      @ok="exportOk"
      @cancel="exportCancel"
    >
      <p>确定导出？</p>
    </a-modal>
    <a-drawer
      title="工地详情"
      placement="right"
      :visible="visibleParticulars"
      :after-visible-change="afterVisibleChangeParticulars"
      @close="onCloseParticulars"
      :width="800"
    >
      <SiteParticulars :record="record" v-if="SiteParticulars" />
    </a-drawer>
  </div>
</template>

<script>
import { getWscountws } from "@/api/dataStatistics.js";
import { STable, ColumnsSet, SSearch } from "@/components";
import { SelectOptions } from "@/config/SelectOptions.js";
import { GetCompanies } from "@/api/device";
import SiteParticulars from "../site/SiteParticulars.vue"; //详情
import { ExportWscountws } from "@/api/export";
import moment from "moment";

// 搜索条件
const queryItems = [
  { label: "工地名称", value: "ws_name", type: "input" },
  {
    label: "工地所属分公司",
    value: "ws_bc_id",
    type: "select",
    selectOption: "CompanyList",
    defaultValue: "",
  },
  {
    label: "项目经理",
    value: "w_jl_name",
    type: "input",
  },
  {
    label: "工程管家",
    value: "w_gj_name",
    type: "input",
  },
  {
    label: "日期查询",
    value: "find_time",
    type: "dateOnlynoDay",
    defaultValue: "",
  },
];
const columns = [
  {
    title: "序号",
    width: "70px",
    scopedSlots: { customRender: "serial" },
    dataIndex: "serial",
    align: "center",
    fixed: "left",
  },

  {
    title: "工地名称",
    width: "220px",
    dataIndex: "ws_name",
    scopedSlots: { customRender: "ws_name" },
    ellipsis: true,
    fixed: "left",
  },
  {
    title: "工地所属分公司",
    width: "150px",
    dataIndex: "bc_name",
    align: "center",
  },
  {
    title: "项目经理",
    width: "100px",
    dataIndex: "w_jl_name",
    align: "center",
  },
  {
    title: "工程管家",
    width: "100px",
    dataIndex: "w_gj_name",
    scopedSlots: { customRender: "w_gj_name" },
    align: "center",
  },
  {
    slots: { title: "整洁度优总次数" },
    // title: "整洁度优总次数",
    width: "185px",
    dataIndex: "cleanworknum",
    scopedSlots: { customRender: "cleanworknum" },
    align: "center",
  },
  {
    slots: { title: "整洁度良总次数" },
    // title: "整洁度良总次数",
    width: "185px",
    dataIndex: "goodworknum",
    scopedSlots: { customRender: "goodworknum" },
    align: "center",
  },
  {
    slots: { title: "整洁度差总次数" },
    // title: "整洁度差总次数",
    width: "185px",
    dataIndex: "badworknum",
    scopedSlots: { customRender: "badworknum" },
    align: "center",
  },
  {
    slots: { title: "摄像头歪曲总次数" },
    // title: "摄像头歪曲总次数",
    width: "200px",
    dataIndex: "camerabadnum",
    scopedSlots: { customRender: "camerabadnum" },
    align: "center",
  },
  {
    slots: { title: "员工抽烟总次数" },
    // title: "员工抽烟总次数",
    width: "185px",
    dataIndex: "smokeworkernum",
    scopedSlots: { customRender: "smokeworkernum" },
    align: "center",
  },
  {
    slots: { title: "非员工抽烟总次数" },
    // title: "非员工抽烟总次数",
    width: "200px",
    dataIndex: "smokeunworkernum",
    scopedSlots: { customRender: "smokeunworkernum" },
    align: "center",
  },
  {
    slots: { title: "未穿工服报警次数" },
    // title: "未穿工服报警次数",
    width: "210px",
    dataIndex: "clothesworkernum",
    scopedSlots: { customRender: "clothesworkernum" },
    align: "center",
  },
  {
    title: "操作",
    width: "80px",
    scopedSlots: { customRender: "action" },
    dataIndex: "action",
    align: "center",
    fixed: "right",
  },
];
const orderParam = [
  "ws_name",
  "ws_bc_id",
  "w_jl_name",
  "w_gj_name",
  "find_type",
  "find_time",
  "sort_column",
  "sort_rule",
  "pageNo",
  "pageSize",
];
export default {
  name: "ConstructionSite",
  components: {
    STable,
    ColumnsSet,
    SSearch,
    SiteParticulars,
  },
  props: {},
  data() {
    this.queryItems = queryItems;
    this.columns = columns;
    const tableSize = { x: 1500, y: document.body.clientHeight - 380 };
    return {
      tableColumns: [],
      sizeList: ["10", "20", "30", "40"], //一页能显示条数
      pageSize: 10, //当前页显示多少条
      current: 1, //当前页
      total: 0, //总条数,在获取后台数据时将数组的length赋值给total
      // 查询参数
      queryParam: {
        find_type: "1", //查询类型
        find_time: "", //查询开始时间（默认昨天）
        sort_column: "", //
        sort_rule: "", //升序降序
      },
      dataSource: [],
      selectedRowKeys: [],
      selectedRows: [],
      //当前页数，当前条数
      pageNo: 1,
      pageSize: 10,
      datepickerType: "date",
      dateValueParents: {},
      advanceFalse: false, //关闭下拉搜索
      now_ws_onworknumBlue: false,
      now_ws_onworknumDownBlue: false,
      now_ws_bindworknumBlue: false,
      now_ws_bindworknumDownBlue: false,
      smokeworkernumBlue: false,
      smokeworkernumDownBlue: false,
      smokeunworkernumBlue: false,
      smokeunworkernumDownBlue: false,
      clothesworkernumBlue: false,
      clothesworkernumDownBlue: false,
      cleanworknumBlue: false,
      cleanworknumDownBlue: false,
      goodworknumBlue: false,
      goodworknumDownBlue: false,
      badworknumBlue: false,
      badworknumDownBlue: false,
      camerabadnumBlue: false,
      camerabadnumDownBlue: false,
      record: {},
      visibleParticulars: false,
      SiteParticulars: false,
      e_time_start: "",
      e_time_end: "",
      // 导出弹出框
      visibleExport: false,
      confirmExportLoading: false,
      isShowBtns: [],
    };
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange,
      };
    },
  },
  created() {
    this.tableColumns = columns;
    this.filters = SelectOptions;
    if (this.queryItems.some((el) => el.selectOption == "CompanyList")) {
      GetCompanies().then((res) => {
        // console.log(res);
        this.filters.CompanyList.length = 0;
        this.filters.CompanyList.push({ value: "0", label: "全部" });
        for (let index = 0; index < res.data.length; index++) {
          const element = res.data[index];
          this.filters.CompanyList.push({
            value: element.bc_id,
            label: element.bc_name,
          });
        }
      });
    }
    let yesterday =
      new Date(new Date().setHours(0, 0, 0, 0)).getTime() -
      1000 * 60 * 60 * 24 * 7; //上周0点的时间戳;
    this.e_time_start = this.$beginOfWeek(yesterday);
    this.e_time_end = this.$endOfWeek(yesterday);
    this.queryItems[4].defaultValue = moment(yesterday);
    this.queryParam.find_time = yesterday / 1000;
  },
  mounted() {
    this.isShowBtns = sessionStorage.getItem("isShowBtns").split(",");
  },
  watch: {},
  methods: {
    // 自定义表头列
    changeColumns() {
      let ColumnsSet = JSON.parse(
        localStorage.getItem("ConstructionSiteColumnsSet")
      );
      let value = ColumnsSet ? ColumnsSet : [];
      this.tableColumns = columns.filter((el) => value.includes(el.dataIndex));
    },
    // 加载数据方法 必须为 Promise 对象
    loadData(parameter) {
      this.pageNo = parameter.pageNo;
      this.pageSize = parameter.pageSize;
      const param = Object.assign({}, parameter, this.queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return getWscountws(params).then((res) => {
        // this.data = res.data.data;
        res.data.pageNo = res.data.page;
        res.data.totalCount = res.data.sum;
        return res.data;
      });
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    fetchPricipal(value) {
      this.queryParam = value;
      if (this.datepickerType == "month") {
        this.queryParam.find_type = "2";
      } else if (this.datepickerType == "week") {
        this.queryParam.find_type = "1";
      } else {
        this.queryParam.find_type = "1";
      }
      // console.log(value);
      this.$refs.table.refresh();
    },
    GetMode(value) {
      console.log(value);
      this.datepickerType = value;
      let today = new Date(new Date().setHours(0, 0, 0, 0)).getTime(); //当天0点的时间戳;
      if (value == "week") {
        this.e_time_start = this.$beginOfWeek(today - 1000 * 3600 * 24 * 7);
        this.e_time_end = this.$endOfWeek(today - 1000 * 3600 * 24 * 7);
      } else if (value == "month") {
        this.e_time_start = this.$beginOfMonth(today - 1000 * 3600 * 24 * 30);
        this.e_time_end = this.$endOfMonth(today - 1000 * 3600 * 24 * 30);
      } else {
        let yesterday = today - 1000 * 60 * 60 * 24; //昨天天0点的时间戳;
        this.e_time_start = yesterday / 1000;
        this.e_time_end = yesterday / 1000;
      }
    },
    getDates(dates) {
      // if (this.datepickerType == "week") {
      //   this.e_time_start = this.beginOfWeek(dates * 1000);
      //   this.e_time_end = this.endOfWeek(dates * 1000);
      // } else
      if (this.datepickerType == "month") {
        this.e_time_start = this.$beginOfMonth(dates * 1000);
        this.e_time_end = this.$endOfMonth(dates * 1000);
      } else {
        // this.e_time_start = dates;
        // this.e_time_end = dates;
        this.e_time_start = this.$beginOfWeek(dates * 1000);
        this.e_time_end = this.$endOfWeek(dates * 1000);
      }
    },
    // 排序
    sortColumn(sort_column, sort_rule, type) {
      console.log(sort_column, sort_rule, type);
      this.queryParam.sort_column = sort_column;
      this.queryParam.sort_rule = sort_rule;
      // this.loadData();
      this.$refs.table.refresh(true);

      if (type) {
        // this[type+'Blue']=true
        this.now_ws_onworknumBlue = false;
        this.now_ws_onworknumDownBlue = false;
        this.now_ws_bindworknumBlue = false;
        this.now_ws_bindworknumDownBlue = false;
        this.smokeworkernumBlue = false;
        this.smokeworkernumDownBlue = false;
        this.smokeunworkernumBlue = false;
        this.smokeunworkernumDownBlue = false;
        this.clothesworkernumBlue = false;
        this.clothesworkernumDownBlue = false;
        this.cleanworknumBlue = false;
        this.cleanworknumDownBlue = false;
        this.goodworknumBlue = false;
        this.goodworknumDownBlue = false;
        this.badworknumBlue = false;
        this.badworknumDownBlue = false;
        this.camerabadnumBlue = false;
        this.camerabadnumDownBlue = false;
        this[type] = true;
        console.log(type);
      }
    },
    // 点击工地地址打开工地详情
    OpenSiteParticulars(text, record) {
      this.visibleParticulars = true;
      this.SiteParticulars = true;
      this.record = record;
      console.log(record);
    },
    afterVisibleChangeParticulars(val) {
      console.log("visible", val);
    },
    onCloseParticulars() {
      this.visibleParticulars = false;
      this.SiteParticulars = false;
    },
    // 跳转到整洁管理页面
    // 传参依次为  工地名称，整洁度事件
    goToSiteNeat(ws_name, e_events) {
      // localStorage.setItem("path", "SiteNeat");
      // const { href } = this.$router.resolve({
      //   path: "/SiteNeat",
      //   query: {
      //     e_time_start: this.e_time_start,
      //     e_time_end: this.e_time_end,
      //     e_events: e_events,
      //     ws_name: ws_name,
      //   },
      // });
      // window.open(href, "_blank");
      this.$store.commit("SET_CURRENT", "SiteNeat");
      this.$router.push({
        path: "/SiteNeat",
        query: {
          e_time_start: this.e_time_start,
          e_time_end: this.e_time_end,
          e_events: e_events,
          ws_name: ws_name,
        },
      });
    },
    // 跳转到报警事件管理页面
    // 传参依次为  工地名称，报警事件，是否为员工,项目经理名称，工程管家名称
    goToAlarmEvent(ws_name, e_events, eventworker_type) {
      // localStorage.setItem("path", "AlarmEvent");
      // const { href } = this.$router.resolve({
      //   path: "/AlarmEvent",
      //   query: {
      //     e_time_start: this.e_time_start,
      //     e_time_end: this.e_time_end,
      //     e_events: e_events,
      //     ws_name: ws_name,
      //     eventworker_type: eventworker_type,
      //   },
      // });
      // window.open(href, "_blank");
      this.$store.commit("SET_CURRENT", "AlarmEvent");
      this.$router.push({
        path: "/AlarmEvent",
        query: {
          e_time_start: this.e_time_start,
          e_time_end: this.e_time_end,
          e_events: e_events,
          ws_name: ws_name,
          eventworker_type: eventworker_type,
        },
      });
    },

    // 导出
    handleExport() {
      this.visibleExport = true;
    },
    exportOk() {
      console.log("ok");
      this.confirmExportLoading = true;
      const params = {
        ws_name: this.queryParam.ws_name,
        ws_bc_id: this.queryParam.ws_bc_id,
        w_jl_name: this.queryParam.w_jl_name,
        w_gj_name: this.queryParam.w_gj_name,
        find_type: this.queryParam.find_type,
        find_time: this.queryParam.find_time,
        sort_column: this.queryParam.sort_column,
        sort_rule: this.queryParam.sort_rule,
      };
      ExportWscountws(params).then((res) => {
        var blob = res;
        var reader = new FileReader();
        reader.readAsDataURL(blob); // 转换为base64，可以直接放入a标签href
        reader.onload = function (e) {
          // 转换完成，创建一个a标签用于下载
          var a = document.createElement("a");
          a.download = "工地形象-按工地统计列表.xls";
          a.href = e.target.result;
          // $("body").append(a);    // 修复firefox中无法触发click
          a.click();
          a.remove();
        };
        this.visibleExport = false;
        this.confirmExportLoading = false;
      });
    },
    exportCancel() {
      this.visibleExport = false;
    },
  },
};
</script>
<style lang="less" scoped>
.nums {
  color: #1890ff;
}
.green {
  // background-color: #dbedfc;
  padding: 0 10px;
  border-radius: 10px;
  color: green;
}
.black {
  // background-color: #dbedfc;
  padding: 0 10px;
  border-radius: 10px;
  color: black;
}
.red {
  // background-color: #dbedfc;
  padding: 0 10px;
  border-radius: 10px;
  color: red;
}

.ws_nameTitle_icon {
  padding-left: 10px;
  cursor: pointer;
}
.TitleFlex {
  display: flex;
  // line-height: 100%;
  align-items: center;
  .Title_icon {
    margin-right: 10px;
    // margin-top: -10px;
    padding-top: 10px;
  }
  .Title_up {
    // margin-bottom: -15px;
    margin-bottom: 0%;
    // background-color: pink;
    width: 15px;
    height: 10px;
    line-height: 10px;
  }
  .Title_down {
    // margin-top: -15px;
    width: 15px;
    height: 10px;
    line-height: 10px;
    // background-color: blue;
    // :hover {
    //   // color: aqua;
    // }
  }
  .BlueBlock {
    color: rgb(3, 104, 255);
  }
}
.blueNum {
  color: rgb(3, 104, 255);
  cursor: pointer;
}
</style>