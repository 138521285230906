import { render, staticRenderFns } from "./Organzational.vue?vue&type=template&id=f48ee49c&scoped=true"
import script from "./Organzational.vue?vue&type=script&lang=js"
export * from "./Organzational.vue?vue&type=script&lang=js"
import style0 from "./Organzational.vue?vue&type=style&index=0&id=f48ee49c&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f48ee49c",
  null
  
)

export default component.exports