import request from "@/utils/request";

const api = {
  getAcountworker: "/dsmcore/api/count/acountworker", //考勤统计按人员统计
  getAcountws: "/dsmcore/api/count/acountws", //考勤统计按工地统计
  getWscountbc: "/dsmcore/api/count/wscountbc", // 工地形象统计按组织统计
  getWscountboss: "/dsmcore/api/count/wscountboss", // 工地形象统计按负责人统计
  getWscountws: "/dsmcore/api/count/wscountws", //工地形象统计按工地统计
  getdaily: "/dsmcore/api/count/daily", //新版日报
};

export function getAcountworker(parameter) {
  return request({
    url: api.getAcountworker,
    method: "post",
    params: parameter,
  });
}
export function getAcountws(parameter) {
  return request({
    url: api.getAcountws,
    method: "post",
    params: parameter,
  });
}
export function getWscountbc(parameter) {
  return request({
    url: api.getWscountbc,
    method: "post",
    params: parameter,
  });
}
export function getWscountboss(parameter) {
  return request({
    url: api.getWscountboss,
    method: "post",
    params: parameter,
  });
}
export function getWscountws(parameter) {
  return request({
    url: api.getWscountws,
    method: "post",
    params: parameter,
  });
}

export function getDaily(parameter) {
  return request({
    url: api.getdaily,
    method: "post",
    params: parameter,
  });
}
