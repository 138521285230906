<template>
  <!-- 工地形象统计->按组织统计 -->
  <!-- style="padding-top: 60px" -->
  <div>
    <s-search
      :queryItems="queryItems"
      @fetchData="fetchOrganzational"
      @mode="GetMode"
      @getDates="getDates"
    ></s-search>
    <div class="table_top">
      <div class="table_info">{{ table_info }}</div>
      <div class="table-button">
        <a-button
          type="plain"
          icon="cloud-download"
          @click="handleExport"
          v-if="isShowBtns.indexOf('SiteImage-/api/count/wscountbcexport') > -1"
          >导出</a-button
        >
        <columns-set
          tableName="Organzational"
          :columns="columns"
          @changeColumns="changeColumns"
        ></columns-set>
      </div>
    </div>
    <s-table
      ref="table"
      size="default"
      :columns="tableColumns"
      rowKey="bcName"
      :data="loadData"
      :showPagination="false"
      :pagination="false"
      :expandedRowKeys="expandedRowKeys"
      @expand="expand"
      @change="changeTable"
      :scroll="{ x: 1200, y: 'calc(82vh - 150px )' }"
    >
      <template slot="sum_now_smokeworkernumFilterIcon">
        <span class="sum_now_smokeworkernumFilterIcon">
          <a-tooltip>
            <template slot="title"
              >统计员工抽烟一人一个工地1小时算一次
            </template>
            <a-icon type="question-circle" class="ws_nameTitle_icon" />
          </a-tooltip>
        </span>
      </template>
      <template slot="sum_now_smokeunworkernumFilterIcon">
        <span class="sum_now_smokeunworkernumFilterIcon">
          <a-tooltip>
            <template slot="title">统计所有非员工抽烟次数</template>
            <a-icon type="question-circle" class="ws_nameTitle_icon" />
          </a-tooltip>
        </span>
      </template>
      <template slot="sum_now_clothesworkernumFilterIcon">
        <span class="sum_now_clothesworkernumFilterIcon">
          <a-tooltip>
            <template slot="title">统计所有员工未穿工服总次数</template>
            <a-icon type="question-circle" class="ws_nameTitle_icon" />
          </a-tooltip>
        </span>
      </template>
      <template slot="sum_now_cleanworknumFilterIcon">
        <span class="sum_now_cleanworknumFilterIcon">
          <a-tooltip>
            <template slot="title">统计整洁度优次数</template>
            <a-icon type="question-circle" class="ws_nameTitle_icon" />
          </a-tooltip>
        </span>
      </template>
      <template slot="sum_now_goodworknumFilterIcon">
        <span class="sum_now_goodworknumFilterIcon">
          <a-tooltip>
            <template slot="title">统计整洁度良次数</template>
            <a-icon type="question-circle" class="ws_nameTitle_icon" />
          </a-tooltip>
        </span>
      </template>
      <template slot="sum_now_badworknumFilterIcon">
        <span class="sum_now_badworknumFilterIcon">
          <a-tooltip>
            <template slot="title">统计整洁度差次数</template>
            <a-icon type="question-circle" class="ws_nameTitle_icon" />
          </a-tooltip>
        </span>
      </template>
      <template slot="sum_now_camerabadnumFilterIcon">
        <span class="sum_now_camerabadnumFilterIcon">
          <a-tooltip>
            <template slot="title">统计整洁度歪曲次数</template>
            <a-icon type="question-circle" class="ws_nameTitle_icon" />
          </a-tooltip>
        </span>
      </template>
      <template slot="sum_now_cleanworknumFilterIcon">
        <span class="sum_now_cleanworknumFilterIcon">
          <a-tooltip>
            <template slot="title"
              >实时在线查询所有绑定工地的设备数量
            </template>
            <a-icon type="question-circle" class="ws_nameTitle_icon" />
          </a-tooltip>
        </span>
      </template>
      <template slot="sum_now_goodworknumFilterIcon">
        <span class="sum_now_goodworknumFilterIcon">
          <a-tooltip>
            <template slot="title"
              >实时在线查询所有绑定工地的设备数量
            </template>
            <a-icon type="question-circle" class="ws_nameTitle_icon" />
          </a-tooltip>
        </span>
      </template>
      <template slot="sum_now_badworknumFilterIcon">
        <span class="sum_now_badworknumFilterIcon">
          <a-tooltip>
            <template slot="title"
              >实时在线查询所有绑定工地的设备数量
            </template>
            <a-icon type="question-circle" class="ws_nameTitle_icon" />
          </a-tooltip>
        </span>
      </template>
      <template slot="sum_now_camerabadnumFilterIcon">
        <span class="sum_now_camerabadnumFilterIcon">
          <a-tooltip>
            <template slot="title"
              >实时在线查询所有绑定工地的设备数量
            </template>
            <a-icon type="question-circle" class="ws_nameTitle_icon" />
          </a-tooltip>
        </span>
      </template>
      <span slot="sum_now_smokeworkernum" slot-scope="text, record">
        <span class="nums" @click="goToAlarmEvent('1', 'smoking', record.z_id)">
          {{ text }}
        </span>
        <span
          :class="
            text - record.sum_old_smokeworkernum > 0
              ? 'red'
              : text - record.sum_old_smokeworkernum < 0
              ? 'green'
              : 'black'
          "
        >
          {{ text - record.sum_old_smokeworkernum < 0 ? "" : "+"
          }}{{ text - record.sum_old_smokeworkernum }}
        </span>
      </span>
      <span slot="sum_now_smokeunworkernum" slot-scope="text, record">
        <span class="nums" @click="goToAlarmEvent('2', 'smoking', record.z_id)">
          {{ text }}
        </span>
        <span
          :class="
            text - record.sum_old_smokeunworkernum > 0
              ? 'red'
              : text - record.sum_old_smokeunworkernum < 0
              ? 'green'
              : 'black'
          "
        >
          {{ text - record.sum_old_smokeunworkernum < 0 ? "" : "+"
          }}{{ text - record.sum_old_smokeunworkernum }}
        </span>
      </span>
      <span slot="sum_now_clothesworkernum" slot-scope="text, record">
        <span
          class="nums"
          @click="goToAlarmEvent('1', 'noclothes', record.z_id)"
        >
          {{ text }}
        </span>
        <span
          :class="
            text - record.sum_old_clothesworkernum > 0
              ? 'red'
              : text - record.sum_old_clothesworkernum < 0
              ? 'green'
              : 'black'
          "
        >
          {{ text - record.sum_old_clothesworkernum < 0 ? "" : "+"
          }}{{ text - record.sum_old_clothesworkernum }}
        </span>
      </span>
      <span slot="sum_now_cleanworknum" slot-scope="text, record">
        <span class="nums" @click="goToSiteNeat('1', record.z_id)">
          {{ text }}
        </span>
        <span
          :class="
            text - record.sum_old_cleanworknum > 0
              ? 'red'
              : text - record.sum_old_cleanworknum < 0
              ? 'green'
              : 'black'
          "
        >
          {{ text - record.sum_old_cleanworknum < 0 ? "" : "+"
          }}{{ text - record.sum_old_cleanworknum }}
        </span>
      </span>
      <span slot="sum_now_goodworknum" slot-scope="text, record">
        <span class="nums" @click="goToSiteNeat('2', record.z_id)">
          {{ text }}
        </span>
        <span
          :class="
            text - record.sum_old_goodworknum > 0
              ? 'red'
              : text - record.sum_old_goodworknum < 0
              ? 'green'
              : 'black'
          "
        >
          {{ text - record.sum_old_goodworknum < 0 ? "" : "+"
          }}{{ text - record.sum_old_goodworknum }}
        </span>
      </span>
      <span slot="sum_now_badworknum" slot-scope="text, record">
        <span class="nums" @click="goToSiteNeat('3', record.z_id)">
          {{ text }}
        </span>
        <span
          :class="
            text - record.sum_old_badworknum > 0
              ? 'red'
              : text - record.sum_old_badworknum < 0
              ? 'green'
              : 'black'
          "
        >
          {{ text - record.sum_old_badworknum < 0 ? "" : "+"
          }}{{ text - record.sum_old_badworknum }}
        </span>
      </span>
      <span slot="sum_now_camerabadnum" slot-scope="text, record">
        <span class="nums" @click="goToSiteNeat('4', record.z_id)">
          {{ text }}
        </span>
        <span
          :class="
            text - record.sum_old_camerabadnum > 0
              ? 'red'
              : text - record.sum_old_camerabadnum < 0
              ? 'green'
              : 'black'
          "
        >
          {{ text - record.sum_old_camerabadnum < 0 ? "" : "+"
          }}{{ text - record.sum_old_camerabadnum }}
        </span>
      </span>
    </s-table>

    <a-modal
      class="tips-modal"
      title="提示"
      okText="确定"
      cancelText="取消"
      :visible="visibleExport"
      :confirm-loading="confirmExportLoading"
      @ok="exportOk"
      @cancel="exportCancel"
    >
      <p>确定导出？</p>
    </a-modal>
  </div>
</template>

<script>
import { STable, ColumnsSet, SSearch } from "@/components";
import { getWscountbc, getWscountboss } from "@/api/dataStatistics.js";
import { ExportWscountbc } from "@/api/export";
import moment from "moment";
// 搜索条件
const queryItems = [
  {
    label: "日期查询",
    value: "find_time",
    type: "dateOnly",
    defaultValue: "",
    // defaultValue: ["", "", ""],
  },
];
const columns = [
  {
    title: "组织名称",
    dataIndex: "bcName",
    key: "bcName",
    sorter: (a, b) => a.bcName.length - b.bcName.length,
    sortDirections: ["descend", "ascend"],
  },
  {
    title: "员工抽烟总次数",
    with: "100px",
    dataIndex: "sum_now_smokeworkernum",
    sorter: (a, b) => a.sum_now_smokeworkernum - b.sum_now_smokeworkernum,
    // defaultSortOrder: "descend", //默认降序，升序用ascend
    scopedSlots: {
      customRender: "sum_now_smokeworkernum",
      filterIcon: "sum_now_smokeworkernumFilterIcon",
    },
    filterDropdown: " ",
    align: "center",
  },
  {
    title: "非员工抽烟总次数",
    with: "100px",
    dataIndex: "sum_now_smokeunworkernum",
    sorter: (a, b) => a.sum_now_smokeunworkernum - b.sum_now_smokeunworkernum,
    scopedSlots: {
      customRender: "sum_now_smokeunworkernum",
      filterIcon: "sum_now_smokeunworkernumFilterIcon",
    },
    filterDropdown: " ",
    align: "center",
  },
  {
    title: "未穿工服总次数",
    with: "100px",
    dataIndex: "sum_now_clothesworkernum",
    sorter: (a, b) => a.sum_now_clothesworkernum - b.sum_now_clothesworkernum,
    defaultSortOrder: "descend", //默认降序，升序用ascend
    align: "center",
    scopedSlots: {
      customRender: "sum_now_clothesworkernum",
      filterIcon: "sum_now_clothesworkernumFilterIcon",
    },
    filterDropdown: " ",
  },
  {
    title: "整洁度优总次数",
    with: "100px",
    dataIndex: "sum_now_cleanworknum",
    sorter: (a, b) => a.sum_now_cleanworknum - b.sum_now_cleanworknum,
    align: "center",
    scopedSlots: {
      customRender: "sum_now_cleanworknum",
      filterIcon: "sum_now_cleanworknumFilterIcon",
    },
    filterDropdown: " ",
  },
  {
    title: "整洁度良总次数",
    with: "100px",
    dataIndex: "sum_now_goodworknum",
    sorter: (a, b) => a.sum_now_goodworknum - b.sum_now_goodworknum,
    align: "center",
    scopedSlots: {
      customRender: "sum_now_goodworknum",
      filterIcon: "sum_now_goodworknumFilterIcon",
    },
    filterDropdown: " ",
  },
  {
    title: "整洁度差总次数",
    with: "100px",
    align: "center",
    dataIndex: "sum_now_badworknum",
    sorter: (a, b) => a.sum_now_badworknum - b.sum_now_badworknum,
    scopedSlots: {
      customRender: "sum_now_badworknum",
      filterIcon: "sum_now_badworknumFilterIcon",
    },
    filterDropdown: " ",
  },
  {
    title: "摄像头歪曲总次数",
    with: "100px",
    dataIndex: "sum_now_camerabadnum",
    sorter: (a, b) => a.sum_now_camerabadnum - b.sum_now_camerabadnum,
    align: "center",
    scopedSlots: {
      customRender: "sum_now_camerabadnum",
      filterIcon: "sum_now_camerabadnumFilterIcon",
    },
    filterDropdown: " ",
  },
  // {
  //   title: "操作",
  //   dataIndex: "action",
  //   width: "160px",
  //   scopedSlots: { customRender: "action" },
  // },
];

const orderParam = ["find_type", "find_time", "sort_column", "sort_rule"];

export default {
  name: "Organzational",
  components: {
    STable,
    ColumnsSet,
    SSearch,
  },
  props: {},
  data() {
    this.queryItems = queryItems;
    this.columns = columns;
    return {
      tableColumns: [],
      // 查询参数
      queryParam: {
        find_type: "1", //查询类型
        find_time: "", //查询开始时间（默认昨天）
        sort_column: "", //
        sort_rule: "", //升序降序
      },
      DrawerTitle: "",
      // 导出弹出框
      visibleExport: false,
      confirmExportLoading: false,
      expandedRowKeys: [],
      datepickerType: "date",
      e_time_start:
        new Date(new Date().setHours(0, 0, 0, 0)).getTime() / 1000 - 3600 * 24,
      e_time_end:
        new Date(new Date().setHours(0, 0, 0, 0)).getTime() / 1000 - 3600 * 24,
      // table_info: "截止昨天统计的数据查询，起始时间2023/10/19之后数据可查询；",
      table_info: "",
      isShowBtns: [],
    };
  },
  created() {
    this.tableColumns = columns;
    this.changeColumns();
    let today = new Date(new Date().setHours(0, 0, 0, 0)).getTime(); //当天0点的时间戳;
    let yesterday = today - 1000 * 60 * 60 * 24;
    // 上周
    let lastWeek = today - 1000 * 60 * 60 * 24 * 7;
    this.queryItems[0].defaultValue = moment(yesterday);
    this.queryParam.find_time = yesterday / 1000;
  },
  watch: {},
  mounted() {
    this.isShowBtns = sessionStorage.getItem("isShowBtns").split(",");
  },
  computed: {},
  methods: {
    // 自定义表头列
    changeColumns() {
      let ColumnsSet = JSON.parse(
        localStorage.getItem("OrganzationalColumnsSet")
      );
      let value = ColumnsSet ? ColumnsSet : [];
      let selectColumns = columns.filter((el) => value.includes(el.dataIndex));
      this.tableColumns = selectColumns.length ? selectColumns : columns;
    },
    // 加载数据方法 必须为 Promise 对象
    loadData(parameter) {
      const param = Object.assign({}, parameter, this.queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return getWscountbc(params).then((res) => {
        let data = res.data.bc_data;
        // console.log(data);
        let valueList = [];
        let children = [];
        data.forEach((item) => {
          // console.log(item);
          children.push({
            bcName: item.bcName,
            z_id: item.bc_id,
            sum_now_badworknum: item.bc_now_badworknum,
            sum_now_camerabadnum: item.bc_now_camerabadnum,
            sum_now_cleanworknum: item.bc_now_cleanworknum,
            sum_now_clothesworkernum: item.bc_now_clothesworkernum,
            sum_now_goodworknum: item.bc_now_goodworknum,
            sum_now_smokeunworkernum: item.bc_now_smokeunworkernum,
            sum_now_smokeworkernum: item.bc_now_smokeworkernum,
            sum_old_badworknum: item.bc_old_badworknum,
            sum_old_camerabadnum: item.bc_old_camerabadnum,
            sum_old_cleanworknum: item.bc_old_cleanworknum,
            sum_old_clothesworkernum: item.bc_old_clothesworkernum,
            sum_old_goodworknum: item.bc_old_goodworknum,
            sum_old_smokeunworkernum: item.bc_old_smokeunworkernum,
            sum_old_smokeworkernum: item.bc_old_smokeworkernum,
          });
        });
        // 按照员工抽烟倒序排列
        // console.log(children);
        // children.sort(function (a, b) {
        //   return b.sum_now_smokeworkernum - a.sum_now_smokeworkernum;
        // });

        valueList.push({
          bcName: res.data.zName,
          z_id: res.data.z_id,
          sum_now_badworknum: res.data.sum_now_badworknum,
          sum_now_camerabadnum: res.data.sum_now_camerabadnum,
          sum_now_cleanworknum: res.data.sum_now_cleanworknum,
          sum_now_clothesworkernum: res.data.sum_now_clothesworkernum,
          sum_now_goodworknum: res.data.sum_now_goodworknum,
          sum_now_smokeunworkernum: res.data.sum_now_smokeunworkernum,
          sum_now_smokeworkernum: res.data.sum_now_smokeworkernum,
          sum_old_badworknum: res.data.sum_old_badworknum,
          sum_old_camerabadnum: res.data.sum_old_camerabadnum,
          sum_old_cleanworknum: res.data.sum_old_cleanworknum,
          sum_old_clothesworkernum: res.data.sum_old_clothesworkernum,
          sum_old_goodworknum: res.data.sum_old_goodworknum,
          sum_old_smokeunworkernum: res.data.sum_old_smokeunworkernum,
          sum_old_smokeworkernum: res.data.sum_old_smokeworkernum,
          children: children,
        });
        // console.log(valueList);
        // console.log(res);
        // 默认展开父节点
        this.expandedRowKeys.push(res.data.zName);
        let resData = {};
        resData.data = valueList;
        return resData;
      });
    },
    // 默认展开父节点时， // 默认行全部展开后，展开收起事件失效，需要手动监听展开收起事件
    expand(e, item) {
      e = !e;
      if (e) {
        this.expandedRowKeys.splice(
          this.expandedRowKeys.findIndex((i) => i == item.bcName),
          1
        );
      } else {
        this.expandedRowKeys.push(item.bcName);
      }
    },
    fetchOrganzational(value) {
      this.queryParam = value;
      if (this.datepickerType == "month") {
        this.queryParam.find_type = "3";
      } else if (this.datepickerType == "week") {
        this.queryParam.find_type = "2";
      } else {
        this.queryParam.find_type = "1";
      }
      console.log(this.$Format(value.find_time));
      this.$refs.table.refresh();
    },
    GetMode(value) {
      console.log(value);
      let today = new Date(new Date().setHours(0, 0, 0, 0)).getTime(); //当天0点的时间戳;
      if (value == "week") {
        // this.table_info =
        //   "截止上一周累计数据查询，起始周2023/第43周之后数据可查询；";
        this.e_time_start = this.$beginOfWeek(today - 1000 * 3600 * 24 * 7);
        this.e_time_end = this.$endOfWeek(today - 1000 * 3600 * 24 * 7);
        // this.queryItems[0].defaultValue = moment(
        //   this.$beginOfWeek(today - 1000 * 3600 * 24 * 7)
        // );
        // this.queryParam.find_time = (today - 1000 * 3600 * 24 * 7) / 1000;
      } else if (value == "month") {
        // this.table_info =
        //   "截止上一个月累计数据查询，起始月2023/11月之后数据可查询；";
        this.e_time_start = this.$beginOfMonth(today - 1000 * 3600 * 24 * 30);
        this.e_time_end = this.$endOfMonth(today - 1000 * 3600 * 24 * 30);
      } else {
        // this.table_info =
        //   "截止昨天统计的数据查询，起始时间2023/10/19之后数据可查询；";
      }
      this.datepickerType = value;
    },
    // 导出
    handleExport() {
      this.visibleExport = true;
    },
    exportOk() {
      console.log("ok");
      this.confirmExportLoading = true;
      const params = {
        find_type: this.queryParam.find_type,
        find_time: this.queryParam.find_time,
        sort_column: this.queryParam.sort_column,
        sort_rule: this.queryParam.sort_rule,
      };
      ExportWscountbc(params).then((res) => {
        var blob = res;
        var reader = new FileReader();
        reader.readAsDataURL(blob); // 转换为base64，可以直接放入a标签href
        reader.onload = function (e) {
          // 转换完成，创建一个a标签用于下载
          var a = document.createElement("a");
          a.download = "工地形象-按组织统计列表.xls";
          a.href = e.target.result;
          // $("body").append(a);    // 修复firefox中无法触发click
          a.click();
          a.remove();
        };
        this.visibleExport = false;
        this.confirmExportLoading = false;
      });
    },
    exportCancel() {
      this.visibleExport = false;
    },
    getDates(dates) {
      if (this.datepickerType == "week") {
        this.e_time_start = this.$beginOfWeek(dates * 1000);
        this.e_time_end = this.$endOfWeek(dates * 1000);
      } else if (this.datepickerType == "month") {
        this.e_time_start = this.$beginOfMonth(dates * 1000);
        this.e_time_end = this.$endOfMonth(dates * 1000);
      } else {
        // 应为昨日日期
        // this.e_time_start = dates * 1 - 60 * 60 * 24;
        // this.e_time_end = dates * 1 - 60 * 60 * 24;
        this.e_time_start = dates * 1;
        this.e_time_end = dates * 1;
        // console.log(this.$Format(this.e_time_start));
      }
      // console.log(this.$Format(this.e_time_start));
    },
    // 打开新窗口   跳转到工地形象-报警事件管理页面
    // 第一个参数为是否为员工，第二个参数为报警事件，弟三个参数为分公司id
    goToAlarmEvent(eventworker_type, e_events, bc_id) {
      // localStorage.setItem("path", "AlarmEvent");
      // // this.$store.commit("SET_CURRENT", "AlarmEvent");
      // const { href } = this.$router.resolve({
      //   path: "/AlarmEvent",
      //   query: {
      //     e_time_start: this.e_time_start,
      //     e_time_end: this.e_time_end,
      //     e_events: e_events,
      //     bc_id: bc_id == "0" ? "" : bc_id,
      //     eventworker_type: eventworker_type,
      //   },
      // });
      // window.open(href, "_blank");
      this.$store.commit("SET_CURRENT", "AlarmEvent");
      this.$router.push({
        path: "/AlarmEvent",
        query: {
          e_time_start: this.e_time_start,
          e_time_end: this.e_time_end,
          e_events: e_events,
          bc_id: bc_id == "0" ? "" : bc_id,
          eventworker_type: eventworker_type,
        },
      });
    },
    // 打开新窗口   跳转到工地形象-整洁管理页面
    // 第一个参数为是否为整洁度事件事件，第二个参数为分公司名称，
    goToSiteNeat(e_events, bc_id) {
      // localStorage.setItem("path", "SiteNeat");
      // // this.$store.commit("SET_CURRENT", "SiteNeat");
      // // console.log("goToSiteNeat", this.$store.state.menu.current);
      // const { href } = this.$router.resolve({
      //   path: "/SiteNeat",
      //   query: {
      //     e_time_start: this.e_time_start,
      //     e_time_end: this.e_time_end,
      //     e_events: e_events,
      //     bc_id: bc_id == "0" ? "" : bc_id,
      //   },
      // });
      // window.open(href, "_blank");
      this.$store.commit("SET_CURRENT", "SiteNeat");
      this.$router.push({
        path: "/SiteNeat",
        query: {
          e_time_start: this.e_time_start,
          e_time_end: this.e_time_end,
          e_events: e_events,
          bc_id: bc_id == "0" ? "" : bc_id,
        },
      });
    },
    changeTable(expanded, record) {
      console.log(expanded, record);
    },
  },
};
</script>
<style lang="less" scoped>
.nums {
  color: #1890ff;
  cursor: pointer;
}
.green {
  // background-color: #dbedfc;
  padding: 0 10px;
  border-radius: 10px;
  color: green;
}
.black {
  // background-color: #dbedfc;
  padding: 0 10px;
  border-radius: 10px;
  color: black;
}
.red {
  // background-color: #dbedfc;
  padding: 0 10px;
  border-radius: 10px;
  color: red;
}
/deep/ .ws_nameTitle_icon {
  display: block;
  margin-top: 80%;

  font-size: 12px;
}
.table_top {
  display: flex;
  justify-content: space-between;
  .table_info {
    color: gray;
  }
}
</style>
