<template>
  <div class="all">
    <div class="buttons">
      <a-button
        class="btn"
        @click="showStatistics('Organzational')"
        :type="showType === 'Organzational' ? 'primary' : ''"
        >按组织统计</a-button
      >
      <a-button
        class="btn PrincipalBtn"
        @click="showStatistics('Principal')"
        :type="showType === 'Principal' ? 'primary' : ''"
        >按主要负责人统计</a-button
      >
      <a-button
        class="btn"
        @click="showStatistics('ConstructionSite')"
        :type="showType === 'ConstructionSite' ? 'primary' : ''"
        >按工地统计</a-button
      >
    </div>
    <div class="statistics">
      <!-- 按组织统计  :dateValueParents="dateValueParents"
          :dateTypeToChild="dateTypeToChild"-->
      <div v-if="showType === 'Organzational'">
        <Organzational />
      </div>
      <!-- 按主要负责人统计 -->
      <div v-if="showType === 'Principal'">
        <Principal :routerPath="routerPath" />
      </div>
      <!-- 按工地统计 -->
      <div v-if="showType === 'ConstructionSite'">
        <ConstructionSite />
      </div>
    </div>
  </div>
</template>

<script>
import Organzational from "./Organzational.vue";
import Principal from "./Principal.vue";
import ConstructionSite from "./ConstructionSite.vue";
export default {
  name: "SiteImage",
  components: { Organzational, Principal, ConstructionSite },
  data() {
    // this.columns = columns;
    return {
      showType: "Organzational",
      datepickerType: "day",
      ShowDateType: "dayShow",
      explainText: "默认统计截止昨日的数据",
      routerPath: {},
    };
  },
  created() {
    if (this.$route.query.showType) {
      console.log(this.$route.query);
      this.showType = this.$route.query.showType;
      this.routerPath = this.$route.query;
    }
  },
  mounted() {},
  watch: {
    datepickerType(nValue) {
      if (nValue == "day") {
        this.explainText = "默认统计截止昨日的数据";
        this.ShowType = "dayShow";
      }
    },
    showType(nValue) {
      this.ShowDateType = "dayShow";
    },
  },
  computed: {},
  methods: {
    showStatistics(type) {
      this.showType = type;
    },
    datepickerChange(e) {
      this.datepickerType = e.target.value;
      this.ShowDateType = e.target.value + "Show";
      if (e.target.value == "day") {
        this.explainText = "截止昨天统计的数据查询";
      } else if (e.target.value == "week") {
        this.explainText = "截止上一周累计数据查询";
      } else if (e.target.value == "month") {
        this.explainText = "统计截止上一周数据";
      }
    },
  },
};
</script>
<style lang="less" scoped>
.all {
  padding-top: 5px;
  width: 100%;
  height: 100%;
  .buttons {
    text-align: left;
    width: 500px;
    //   background-color: pink;
    .btn {
      width: 120px;
      height: 40px;
      // border: 1px solid #40a9ff;
      border-radius: 0;
    }
    .PrincipalBtn {
      width: 150px;
    }
  }
  .AllDateSearchBtn {
    text-align: left;
    margin-top: 20px;
    .btn {
      //   background-color: pink;
      height: 40px;
      display: flex;
      .radioBTN {
        // width: 100px;
        margin-right: 10px;
      }
      .datePick {
        .searchBtn {
          margin-left: 10px;
          // width: 50px;
        }
      }
    }

    .explainText {
      color: gray;
    }
  }
  .statistics {
    // background-color: pink;
    width: 100%;
    // height: calc(82vh - 150px);
    height: 85%;
  }
}
</style>
